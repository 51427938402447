class Package {
  constructor(packageObj) {
    Object.assign(this, packageObj)
  }

  needsPayment() {
    return this.paymentInfo?.status === 'authorized'
  }

  isOffline() {
    return this.paymentInfo?.metadata?.paymentMethod === 'offline'
  }

  isPaid() {
    if (!this.isOffline()) return true
    return this.paymentInfo?.status === 'paid'
  }
}

module.exports = { Package }
