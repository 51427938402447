<template>
  <ion-accordion-group v-if="isInstructor || props.lesson.instructorNotes" data-testid="instructor-notes" :value="isOpen ? 'instructor-notes' : null">
    <ion-accordion value="instructor-notes">
      <ion-item slot="header" color="light">
        Lesson Notes
      </ion-item>
      <div slot="content" class="ion-padding">
        <template v-if="isInstructor && isEditing">
          <ion-textarea
            v-model="notes" :label="`Notes for ${lesson.student?.name}`"
            placeholder="Add notes about this lesson. You can use:
- Bold: **text**
- Italic: *text*
- Lists: Start lines with - or *
- Paragraphs: Double space between them"
            auto-grow="true" label-placement="stacked" rows="8"
            mode="md" fill="outline" counter="true"
            maxlength="10000" :disabled="isSaving" autocapitalize="on"
          />
          <ion-button
            expand="block" fill="outline" color="success"
            size="small"
            :disabled="!hasChanges || isSaving"
            @click="saveNotes"
          >
            <ion-spinner v-if="isSaving" name="crescent" />
            <span v-else>Save Notes</span>
          </ion-button>
        </template>
        <template v-else>
          <NotesDisplay
            :notes="lesson.instructorNotes"
            :author="lesson.instructor?.name"
            :timestamp="(lesson.notesUpdatedAt?.seconds || 0) * 1000"
          />

          <ion-button
            v-if="isInstructor"
            expand="block" fill="outline"
            size="small"
            @click="isEditing = true"
          >
            Edit Notes
          </ion-button>
        </template>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup>
import { IonAccordion, IonAccordionGroup, IonButton, IonItem, IonSpinner, IonTextarea } from '@ionic/vue'
import { computed, ref } from 'vue'

import NotesDisplay from '@/shared/components/NotesDisplay.vue'

const props = defineProps({
  lesson: { type: Object, required: true },
  currentUserType: { type: String, required: true, default: 'student' },
})

const emit = defineEmits(['update:notes'])

const isInstructor = computed(() => props.currentUserType === 'instructor')
const notes = ref(props.lesson.instructorNotes || '')
const hasChanges = computed(() => notes.value !== props.lesson.instructorNotes)
const isSaving = ref(false)
const isOpen = props.lesson.instructorNotes?.length > 0
const isEditing = ref(!props.lesson.instructorNotes)

async function saveNotes() {
  isSaving.value = true
  try {
    await emit('update:notes', notes.value)
    isEditing.value = false
  } finally {
    isSaving.value = false
  }
}
</script>
