<template>
  <div id="info-modal" class="ion-padding" data-testid="cancellation-modal">
    <p>
      <strong>Need to cancel?</strong>
      <br>
      Consider rescheduling instead!
    </p>
    <p v-if="currentUserType == 'student'">
      Connect with your instructor to find a new time. Remember, you get a full refund (minus a ~3% processing fee) with 24+ hours notice. No refund otherwise.
    </p>
    <ion-textarea
      v-model.trim="cancellationReason" fill="outline" mode="md"
      placeholder="Provide reason (required)" rows="1"
      data-testid="lesson-cancel-reason" auto-grow="true" autocapitalize="on"
    />
    <ion-button
      color="danger" fill="solid" expand="block"
      size="default" class="ion-margin-top"
      data-testid="lesson-cancel-confirm" :disabled="!cancellationReason"
      @click="confirmCancellation"
    >
      Cancel
    </ion-button>
  </div>
</template>

<script setup>
import { IonButton, IonTextarea, modalController } from '@ionic/vue'
import { ref } from 'vue'

defineProps({
  currentUserType: { type: String, required: true },
})

const cancellationReason = ref('')

function confirmCancellation() {
  modalController.dismiss(cancellationReason.value, 'confirmCancellation')
}
</script>

<style>
/* CAN NOT BE SCOPED */
ion-modal.cancel-lesson-modal {
  --width: 90%;
  --max-width: 90%;
  --min-width: 250px;
  --min-height: 150px;
  --height: fit-content;
  --max-height: 90%;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
</style>
