<template>
  <div>
    <ion-grid>
      <ion-row>
        <ion-col>
          <h3 style="font-weight: normal;">{{ filteredPackages.length }} Package{{ filteredPackages.length === 1 ? '' : 's' }}</h3>
        </ion-col>
        <ion-col size="auto">
          <h3 style="font-weight: normal;">{{ formatMoney(getPackageEarnings(filteredPackages)) }}</h3>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-select v-model="filterState" interface="action-sheet" label="Status">
            <ion-select-option value="all">
              All
            </ion-select-option>
            <ion-select-option value="active">
              Active
            </ion-select-option>
            <ion-select-option value="completed">
              Completed
            </ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-list v-if="packagesGroupsByMonths.length > 0">
      <ion-item-group v-for="([month, totalEarnings, packages]) in packagesGroupsByMonths" :key="month">
        <ion-item-divider sticky="true" style="--inner-padding-end: 0;">
          <ion-grid style="padding-left: 0; padding-right: 0">
            <ion-row>
              <ion-col style="padding-left: 0; font-weight: normal;">
                {{ month }}
              </ion-col>
              <ion-col v-if="totalEarnings != 0" size="auto" style="padding-right: 0;text-align: right; font-weight: normal;">
                {{ formatMoney(totalEarnings) }}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item-divider>
        <ion-item
          v-for="pckg in packages" :key="pckg.id" button="true"
          :router-link="{name: 'package', params: {packageId: pckg.id}}"
        >
          <ion-label>
            <h2>{{ pckg.student.name }}</h2>
            <p>
              {{ pckg.lessonsLeft }} / {{ pckg.lessons }} lessons remaining
            </p>
            <p>Purchased {{ formatDate(pckg.createdAt.seconds * 1000, 'PPP') }}</p>
          </ion-label>
          <ion-note slot="end">
            {{ formatMoney(pckg.paymentInfo?.metadata?.packagePrice || pckg.price) }}
          </ion-note>
        </ion-item>
      </ion-item-group>
    </ion-list>
    <div v-else class="ion-text-center ion-padding ion-margin-top">
      <ion-icon :icon="golfOutline" size="large" color="medium" />
      <p>No packages yet</p>
    </div>
  </div>
</template>

<script setup>
import { IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonNote, IonRow, IonSelect, IonSelectOption } from '@ionic/vue'
import { computed, ref } from 'vue'
import { formatDate } from 'date-fns'
import { golfOutline } from 'ionicons/icons'

import { LessonsRepository } from '@/data/LessonsRepository'
import { formatMoney } from '@/shared/utils/formatMoney'

const LessonsRepo = new LessonsRepository()
const filterState = ref('active')
const allPackages = LessonsRepo.getLessonPackages()

const filteredPackages = computed(() => {
  switch (filterState.value) {
    case 'active':
      return allPackages.value.filter(pckg => pckg.lessonsLeft > 0)
    case 'completed':
      return allPackages.value.filter(pckg => pckg.lessonsLeft === 0)
    default:
      return allPackages.value
  }
})

function getPackageEarnings(packages) {
  return packages.reduce((total, pckg) => {
    return total + (parseFloat(pckg.paymentInfo?.metadata?.packagePrice || pckg.price) || 0)
  }, 0).toFixed(2)
}

const packagesGroupsByMonths = computed(() => {
  const packagesGroups = filteredPackages.value.reduce((acc, pckg) => {
    const date = new Date(pckg.createdAt.seconds * 1000)
    const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
    if (!acc[monthYear]) {
      acc[monthYear] = []
    }
    acc[monthYear].push(pckg)
    return acc
  }, {})

  return Object.entries(packagesGroups)
    .sort((a, b) => b[0].localeCompare(a[0]))
    .map(([monthYear, packages]) => {
      const monthName = new Date(`${monthYear}-15`).toLocaleString('en-US', { month: 'long', year: 'numeric' })
      return [monthName, getPackageEarnings(packages), packages]
    })
})
</script>

<style scoped>
ion-item-divider {
  --padding-start: 1rem;
  --padding-end: 1rem;
}
</style>
