<template>
  <ion-card>
    <ion-card-content>
      <template v-for="userType in ['student', 'instructor']">
        <ion-item
          v-if="currentUser != userType && !hide.includes(userType) && lesson[userType]"
          :key="userType" :data-testid="`lesson-${userType}`"
          lines="none" detail="true" button="true"
          :router-link="linkToUserProfile"
        >
          <user-avatar slot="start" :url="lesson[userType].photoURL" :name="lesson[userType].name" />
          <ion-label style="text-transform: capitalize">
            {{ lesson[userType].name }}
            <span v-if="offlineStudent">
              <ion-icon :icon="cloudOfflineOutline" />
            </span>
          </ion-label>
        </ion-item>
      </template>
      <ion-item lines="none" data-testid="lesson-timeslot">
        <strong>{{ lesson.formattedDate }}, {{ displayFullLength(lesson.time || lesson.formattedTime, lesson.length || lesson.lessonType?.length) }}</strong>
        <span v-if="!hide.includes('lesson-type')" lines="none">
          ,&nbsp;{{ lesson.pricing.name }}
        </span>
      </ion-item>
      <ion-item v-if="!hide.includes('location') && calculatedLocation" lines="none">
        <p>
          <strong>{{ calculatedLocation.name }}</strong>
          <br>
          <a :href="`https://www.google.com/maps/search/?api=1&query=${calculatedLocation.address}`" target="_blank">
            {{ calculatedLocation.address }}
          </a>
        </p>
      </ion-item>
      <ion-item v-if="lesson.id && lesson.toIcs && currentUser === 'student'" lines="none">
        <ics-download :lesson="lesson" :current-user="currentUser" />
      </ion-item>
      <ion-item v-if="lesson.description && !hide.includes('description')" lines="none" data-testid="lesson-description">
        <p>
          <strong>Description:</strong>
          "{{ lesson.description }}"
        </p>
      </ion-item>
      <ion-item v-if="!lesson.id && !hide.includes('notes') || (lesson.id && lesson.notes)" lines="none">
        <ion-textarea
          v-if="!lesson.id" v-model="notes" auto-grow="true"
          label="Notes" label-placement="stacked" placeholder="Any special requests or things you'd like to work on?"
          data-testid="lesson-notes" fill="outline" mode="md"
          rows="3" class="ion-margin-top" autocapitalize="on"
        />
        <template v-if="lesson.id && lesson.notes">
          <p data-testid="lesson-notes">
            <br>
            <strong>Student Notes:</strong>
            "{{ lesson.notes }}"
          </p>
        </template>
      </ion-item>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import {
  IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonTextarea,
} from '@ionic/vue'
import { cloudOfflineOutline } from 'ionicons/icons'
import { computed } from 'vue'

import IcsDownload from '@/views/golfers/lessons/IcsDownload.vue'
import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { displayFullLength } from '@/6_shared/utils/formattingLessons.js'

const props = defineProps({
  lesson: { type: Object, required: true },
  currentUser: { type: String, required: true, default: 'guest' },
  hide: { type: Array, default: () => [] },
})

const notes = defineModel('notes', { type: String, default: '' })
const calculatedLocation = computed(() => props.lesson.location || props.lesson.lessonType?.location)

const offlineStudent = computed(() => props.currentUser === 'instructor' && props.lesson.studentId.startsWith('offline_'))

const otherUserType = computed(() => props.currentUser === 'instructor' ? 'student' : 'instructor')

const linkToUserProfile = computed(() => {
  const otherUserTypeId = `${otherUserType.value}Id`
  return {
    name: otherUserType.value,
    params: {
      [otherUserTypeId]: props.lesson[otherUserTypeId],
    },
  }
})

</script>
