<template>
  <div v-if="filteredPurchasedPackages.length">
    <h2 class="ion-padding-start">Packages</h2>
    <ion-list style="margin:0">
      <ion-item
        v-for="pckg in filteredPurchasedPackages" :key="pckg.id" lines="full"
        :data-id="pckg.id"
        :router-link="userStore.is.instructor ? { name: 'package', params: { packageId: pckg.id } } : null "
        :detail="userStore.is.instructor"
      >
        <ion-grid class="ion-no-padding">
          <ion-row class="ion-align-items-center ion-padding-vertical">
            <ion-col>
              <strong>{{ pckg.lessonsLeft }} of {{ pckg.lessons }} {{ pckg.name }}</strong> prepaid lessons left {{ otherPartyName(pckg) }}
              <ion-note :color="pckg.isPaid() ? 'success' : 'tertiary'">
                <small>{{ pckg.isPaid() ? 'paid' : 'payment pending' }}</small>
              </ion-note>
            </ion-col>
            <ion-col v-if="displayBookButton && userStore.is.student" size="auto" class="ion-no-padding">
              <ion-button
                fill="outline" color="success"
                size="small" style="margin-left: 1em"
                data-testid="package-book-button"
                :disabled="pckg.lessonsLeft == 0"
                @click="bookLesson(pckg)"
              >
                Book
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-list>
  </div>
</template>

<script setup>
import { IonButton, IonCol, IonGrid, IonItem, IonList, IonNote, IonRow } from '@ionic/vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { LessonsRepository } from '@/data/LessonsRepository'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const props = defineProps({
  displayBookButton: { required: false, type: Boolean, default: true },
  filterByUser: { required: false, type: Object, default: null },
})

const router = useRouter()

const LessonsRepo = new LessonsRepository()
const purchasedPackages = LessonsRepo.getLessonPackages()

const filteredPurchasedPackages = computed(() => {
  const result = purchasedPackages.value.filter(pckg => pckg.lessonsLeft > 0 || pckg.needsPayment()).sort((a, b) => b.paymentInfo?.status < a.paymentInfo?.status ? 1 : -1)
  if (!props.filterByUser) return result
  return result.filter(pckg => pckg.instructor.id === props.filterByUser.id || pckg.student.id === props.filterByUser.id)
})

function otherPartyName(pckg) {
  if (props.filterByUser) return ''
  if (userStore.is.student) return `with ${pckg.instructor.name}`
  if (userStore.is.instructor) return `with ${pckg.student.name}`
}

async function bookLesson(pckg) {
  router.push({ name: 'bookLesson', params: { instructorId: pckg.instructor.id }, query: { lessonType: pckg.name }})
}

</script>
