<template>
  <ion-page data-testid="package-page">
    <ion-header>
      <ion-toolbar>
        <ion-title>Package Details</ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{ name: 'lessons' }" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content color="light">
      <error-message
        v-if="error"
        :title="error.title"
        :content="error.content"
      />
      <error-message
        v-if="!pckg"
        title="Package Not Found"
        content="The package you are looking for does not exist"
      />

      <template v-if="pckg && userStore.is.instructor">
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{ pckg.lessons }} {{ pckg.name }} Lessons</ion-card-title>
          </ion-card-header>

          <ion-card-content class="ion-no-padding">
            <ion-list>
              <ion-item
                lines="none" detail="true" button="true"
                :router-link="{ name: 'student', params: { studentId: pckg.student.id } }"
              >
                <user-avatar slot="start" :url="pckg.student.photoURL" :name="pckg.student.name" />
                <ion-label style="text-transform: capitalize" data-testid="package-student-name">
                  {{ pckg.student.name }}
                </ion-label>
              </ion-item>
              <ion-item lines="full">
                <ion-label>Total Lessons</ion-label>
                <ion-note slot="end">{{ pckg.lessons }}</ion-note>
              </ion-item>

              <ion-item lines="full">
                <ion-label>Lessons Left</ion-label>
                <ion-note slot="end">{{ pckg.lessonsLeft }}</ion-note>
              </ion-item>

              <ion-item lines="full">
                <ion-label>Price</ion-label>
                <ion-note slot="end">{{ formatMoney(pckg.price) }}</ion-note>
              </ion-item>

              <ion-item lines="full">
                <ion-label>Created</ion-label>
                <ion-note slot="end">{{ formatDate(pckg.createdAt.seconds * 1000, 'PPP') }}</ion-note>
              </ion-item>

              <ion-item lines="none">
                <ion-label>Payment Status</ion-label>
                <ion-note slot="end" :color="pckg.isPaid() ? 'success' : 'tertiary'">
                  {{ pckg.isPaid() ? 'Paid' : 'Payment Pending' }}
                </ion-note>
              </ion-item>
              <ion-item v-if="pckg.isOffline() && userStore.is.instructor" lines="none">
                <p>
                  <template v-if="pckg.needsPayment()">
                    <ion-button @click="markAsPaid(pckg)">Mark as Paid (${{ pckg.paymentInfo.metadata.totalPayment }})</ion-button>
                    <ion-button fill="outline" @click="sendPaymentReminder(pckg)">Send Payment Reminder</ion-button>
                  </template>
                  <ion-button
                    v-else size="small" fill="outline"
                    color="danger" @click="markAsUnpaid(pckg)"
                  >
                    Mark as Unpaid
                  </ion-button>
                </p>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>

        <div class="ion-padding">
          <h2>Edit Package</h2>

          <ion-list>
            <ion-item lines="none" style="overflow: visible" class="ion-margin-bottom ion-margin-top">
              <ion-input
                v-model="pckg.lessonsLeft"
                label="Lessons Left" label-placement="stacked"
                fill="outline" mode="md" type="number"
                :min="0" :max="pckg.lessons"
                helper-text="Change this to allow the student to book more or less lessons from this package"
              />
            </ion-item>
            <ion-item lines="none" style="overflow: visible">
              <ion-textarea
                v-model="pckg.notes" :auto-grow="true" maxlength="250"
                label="Private Notes" label-placement="stacked"
                :counter="true"
                rows="3" mode="md" fill="outline"
                placeholder="Only visible to you" autocapitalize="on"
              />
            </ion-item>
          </ion-list>

          <ion-button
            expand="block"
            class="ion-margin-top ion-margin-bottom"
            @click="saveChanges"
          >
            Save Changes
          </ion-button>
        </div>
        <lessons-block :filter-by-package="pckg" :display-state-description="false" :display-title="true" />
      </template>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonBackButton, IonButton, IonButtons, IonCard, IonCardContent,
  IonCardHeader, IonCardTitle, IonContent, IonHeader,
  IonInput, IonItem, IonLabel, IonList, IonNote, IonPage, IonTextarea,
  IonTitle, IonToolbar, toastController,
} from '@ionic/vue'
import { formatDate } from 'date-fns'
import { ref } from 'vue'

import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import LessonsBlock from '@/shared/components/LessonsBlock.vue'
import { LessonsRepository } from '@/data/LessonsRepository'
import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { formatMoney } from '@/shared/utils/formatMoney'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { useUserStore } from '@/stores/user'

const props = defineProps({
  packageId: { type: String, required: true },
})

const userStore = useUserStore()
const error = ref(null)
const LessonsRepo = new LessonsRepository()

const pckg = LessonsRepo.getPackage(props.packageId)

// Handle unauthorized access
if (!userStore.is.instructor) {
  error.value = {
    title: 'Unauthorized',
    content: 'Only instructors can access this page',
  }
}

async function saveChanges() {
  await sendRequest('Updating Package...', async () => {
    if (pckg.value.lessonsLeft < 0) {
      pckg.value.lessonsLeft = 0
    }
    await LessonsRepo.updatePackage({ packageId: props.packageId, updates: {
      lessonsLeft: parseInt(pckg.value.lessonsLeft),
      notes: pckg.value.notes,
    }})
  })
}

async function markAsPaid(pckg) {
  await sendRequest('Updating Package...', async () => {
    await LessonsRepo.updateOfflinePaymentStatus({ pckg: pckg, paymentStatus: 'paid' })
  })
}

async function markAsUnpaid(pckg) {
  await sendRequest('Updating Package...', async () => {
    await LessonsRepo.updateOfflinePaymentStatus({ pckg: pckg, paymentStatus: 'authorized' })
  })
}

async function sendPaymentReminder(pckg) {
  await sendRequest('Sending Payment Reminder...', async () => {
    await LessonsRepo.sendPaymentReminder({ pckg: pckg })
  })
  const toast = await toastController.create({
    message: 'Payment Reminder Sent',
    duration: 1000,
    color: 'success',
  })
  await toast.present()
}
</script>
