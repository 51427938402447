<template>
  <div class="message" :class="isMine ? 'message-mine' : 'message-other'">
    <user-avatar v-if="isLastRead" :size="25" :url="avatarUrl" />
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-once class="message-content" v-html="formattedMessage" />
    <div v-once class="message-timestamp">
      <small>{{ formattedTimestamp }}</small>
    </div>
  </div>
</template>

<script setup>
import { formatDate } from 'date-fns'

import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { simpleFormat } from '@/6_shared/utils/formatText'

const props = defineProps({
  message: { type: Object, required: true },
  isLastRead: Boolean,
  isMine: Boolean,
  avatarUrl: { type: [String, null], required: true },
})
const formattedTimestamp = formatDate(props.message.createdAt.seconds * 1000, 'MMM dd, h:mm a')
const formattedMessage = simpleFormat(props.message.message)
</script>

<style>
/* SCOPED doesn't work with .message-mine a */
.message {
  padding: 8px 12px;
  margin: 8px 0;
  border-radius: 16px;
  max-width: 80%; /* Avoid excessively wide messages */
  clear: both; /* Prevent overlapping with floats */
  position: relative;
}

.message p:first-child {
  margin-top: 0;
}
.message p:last-child {
  margin-bottom: 0;
}

.message ion-avatar {
  position: absolute;
  margin-right: 8px;
  top: -10px;
  background-color: white;
  border: 1px solid var(--ion-color-primary);
}
.message-mine ion-avatar {
  left: -10px;
}
.message-other ion-avatar {
  right: -20px;
}

.message-mine {
  float: right;
  background-color: var(--ion-color-secondary-tint);
  color: var(--ion-color-secondary-contrast);
}

.message-mine a {
  color: white !important;
}

.message-mine a {
  color: white !important;
}

.message-other {
  float: left;
  background-color: var(--ion-color-light-shade);
  color: var(--ion-color-light-contrast);
}
.message-mine .message-timestamp{
  text-align: right;
  color: var(--ion-color-secondary-contrast);
}
.message-other .message-timestamp{
  text-align: left;
  color: var(--ion-color-light-contrast);
}
.message-timestamp {
  font-size: 12px;
  margin-top: 4px;
}
.message-content {
  word-wrap: break-word;  /* Ensure long words don't break the layout */
}
</style>
