import { escape } from 'lodash'

function sanitizeText(text) {
  return escape(text)
}

export function formatText(text) {
  // Sanitize the entire text first
  const safeText = sanitizeText(text)

  // Function to apply inline formatting (bold, italics, and line breaks)
  function applyInlineFormatting(text) {
    // Bold: **text**
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    // Italics: *text*
    text = text.replace(/\*((?!\*).+?)\*/g, '<em>$1</em>')
    // make links clickable and open in new page
    text = text.replace(/(https?:\/\/[^\s]+)/g, url =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`,
    )
    return text
  }

  // Apply inline formatting to the entire text
  const formattedText = applyInlineFormatting(safeText)

  // Split the text into blocks
  const blocks = formattedText.split(/\n{2,}/)

  // Format each block (paragraph or list)
  const formattedBlocks = blocks.map((block) => {
    const lines = block.split('\n')

    // Check if it's a numbered list - looking for at least one numbered item
    if (lines.some(line => /^\s*\d+\./.test(line))) {
      const listItems = []
      const numberedItems = []

      lines.forEach((line) => {
        if (/^\s*\d+\./.test(line)) {
          numberedItems.push(`<li>${line.replace(/^\s*\d+\.\s*/, '')}</li>`)
        } else {
          if (numberedItems.length) {
            listItems.push(`<ol>${numberedItems.join('')}</ol>`)
            numberedItems.length = 0
          }
          listItems.push(`<p>${line}</p>`)
        }
      })
      if (numberedItems.length) {
        listItems.push(`<ol>${numberedItems.join('')}</ol>`)
      }
      return listItems.join('')
    }

    // Check if it's a bullet point list (- or *) - looking for at least one bullet
    if (lines.some(line => /^\s*[-*]/.test(line))) {
      const listItems = []
      const bulletItems = []

      lines.forEach((line) => {
        if (/^\s*[-*]/.test(line)) {
          bulletItems.push(`<li>${line.replace(/^\s*[-*]\s*/, '')}</li>`)
        } else {
          if (bulletItems.length) {
            listItems.push(`<ul>${bulletItems.join('')}</ul>`)
            bulletItems.length = 0
          }
          listItems.push(`<p>${line}</p>`)
        }
      })
      if (bulletItems.length) {
        listItems.push(`<ul>${bulletItems.join('')}</ul>`)
      }
      return listItems.join('')
    }

    // It's a regular paragraph or multiple lines
    return `<p>${lines.join('<br>')}</p>`
  })

  return formattedBlocks.join('') // Join the formatted blocks
    .replace('<ol></ol>', '') // remove empty <ol> tags
}

export function simpleFormat(text) {
  // Sanitize the text first
  const safeText = sanitizeText(text)

  // Make URLs clickable and secure
  const withLinks = safeText.replace(
    /(https?:\/\/[^\s]+)/g,
    url => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`,
  )

  // Split the text into blocks (paragraphs)
  const blocks = withLinks.split(/\n{2,}/)

  // Format each block as a paragraph, with single line breaks as <br>
  const formattedBlocks = blocks.map((block) => {
    const lines = block.split('\n')
    return `<p>${lines.join('<br>')}</p>`
  })

  return formattedBlocks.join('')
}
