<template>
  <ion-item
    detail="true" data-testid="lesson-item" :data-lesson-id="lesson.id"
    lines="full" button="true"
  >
    <user-avatar
      v-if="displayUser" slot="start" :url="userAvatarUrl"
      :name="lesson[otherPartyName].name"
    />
    <lesson-status
      v-if="!displayUser" slot="start" :lesson="lesson"
      :display-text="false" :display-notes-icon="true"
    />
    <ion-label>
      <span v-if="displayUser">{{ lesson[otherPartyName].name }}<br></span>
      <p>
        {{ lesson.formattedDate }}, {{ displayFullLength(lesson.time, lesson.length) }}
        <br>
        <lesson-status
          v-if="displayUser && !displayNotes" slot="start" :lesson="lesson"
          :display-notes-icon="true"
        />
        <ion-note v-if="lesson.needToCollectPayment()" color="danger">
          {{ lesson.paymentStatus() }}
        </ion-note>
        <ion-note v-if="!lesson.needToCollectPayment() && !displayUser">
          {{ lesson.paymentStatus() }}
        </ion-note>
      </p>
      <NotesDisplay v-if="displayNotes && lesson.hasInstructorNotes()" :notes="`${lesson.instructorNotes.slice(0, 100)}...`" />
    </ion-label>
    <ion-icon
      v-if="lesson.isAwaitingActionBy(currentUser)" slot="end" :icon="ellipse"
      data-testid="unread-icon" style="color: #1982FC; fill: #1982FC" size="small"
    />
  </ion-item>
</template>

<script setup>
import { IonIcon, IonItem, IonLabel, IonNote } from '@ionic/vue'
import { ellipse } from 'ionicons/icons'

import LessonStatus from '@/4_features/LessonStatus.vue'
import NotesDisplay from '@/shared/components/NotesDisplay.vue'
import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { displayFullLength } from '@/6_shared/utils/formattingLessons'
import { useUserStore } from '@/stores/user'
const userStore = useUserStore()

const props = defineProps({
  lesson: { type: Object, required: true },
  currentUser: { type: String, required: true },
  displayUser: { type: Boolean, default: true },
  displayNotes: { type: Boolean, default: false },
})

const userAvatarUrl = props.currentUser === 'student' ? props.lesson.instructor.photoURL : props.lesson.student.photoURL

const otherPartyName = userStore.is.student ? 'instructor' : 'student'

</script>

<style scoped>
ion-label {
  text-transform: capitalize;
}
</style>
