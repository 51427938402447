<template>
  <ion-page data-testid="lessontype-modal">
    <ion-header>
      <ion-toolbar>
        <ion-title data-testid="lesson-status">
          <span v-if="subPage== 'general'">Lesson Info</span>
          <span v-if="subPage== 'schedule'">Lesson Schedule</span>
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'schedules'}" @click="cancel" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light">
      <div v-if="selectedSubPage == 'general'" id="general_block">
        <!------------->
        <!-- General -->
        <!------------->
        <!-- Location -->
        <ion-item lines="none">
          <h3>General</h3>
        </ion-item>
        <ion-list>
          <ion-item id="select-location" lines="none" :button="true">
            <ion-label>Location</ion-label>
            <span v-if="lesson.location">{{ lesson.location?.name }}</span>
            <span v-if="!lesson.location">
              <ion-button fill="outline" size="small" color="medium">
                Select Location
              </ion-button>
            </span>
          </ion-item>
          <ion-modal ref="locationsModal" trigger="select-location">
            <locations-selector
              :items="EDMONTON_LOCATIONS"
              :selected-items="[lesson.location]"
              title="Select Location" mode="single"
              @selection-change="lesson.location = $event[0]; locationsModal.$el.dismiss()"
            />
          </ion-modal>
          <ion-item lines="none">
            <ion-select
              v-model="lesson.length" interface="action-sheet" label="Lesson Length"
              placeholder="Set Length"
            >
              <ion-select-option v-for="minutes in POSSIBLE_LESSON_LENGTHS" :key="minutes" :value="minutes">{{ minutes }} min</ion-select-option>
            </ion-select>
          </ion-item>
          <!-- Description -->
          <ion-item lines="full" style="overflow:visible" class="ion-margin-top">
            <ion-textarea
              v-model="lesson.description" :auto-grow="true" maxlength="250"
              label="Description" label-placement="stacked" :counter="true"
              rows="3" mode="md" fill="outline"
              placeholder="Describe your lesson offering" autocapitalize="on"
            />
          </ion-item>
        </ion-list>
        <!------------->
        <!-- Pricing -->
        <!------------->
        <ion-list>
          <ion-item lines="none">
            <div>
              <h3>Pricing</h3>
              <ion-note>Pricing is for the entire lesson, not per person</ion-note>
            </div>
          </ion-item>
          <ion-item
            v-for="pricingType in lesson.pricing" :key="pricingType" lines="full"
            class="ion-margin-top" :data-testid="`pricing-${pricingType.key}`"
          >
            <ion-grid>
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <ion-checkbox
                    v-model="pricingType.enabled" label-placement="end" justify="start"
                    @ion-change="v$.pricing.$touch"
                  >
                    {{ pricingType.name }}
                  </ion-checkbox>
                </ion-col>
                <ion-col size="5">
                  <ion-input
                    v-model.number="pricingType.price" type="number" fill="outline"
                    mode="md" placeholder="" :disabled="!pricingType.enabled"
                    label="Price per lesson" label-placement="stacked"
                  >
                    <span slot="start">$</span>
                  </ion-input>
                </ion-col>
              </ion-row>
              <template v-if="pricingType.packages?.length">
                <ion-row>
                  <ion-col>
                    <br>
                    <ion-label><strong>Lesson Packages:</strong></ion-label>
                    <br>
                  </ion-col>
                </ion-row>
                <ion-row
                  v-for="lessonPackage in pricingType.packages" :key="lessonPackage" class="ion-align-items-center"
                  data-testid="lesson-packages"
                >
                  <ion-col size="auto">
                    <ion-button
                      fill="clear" size="small" color="danger"
                      data-testid="remove-package-button" @click="removePackage(pricingType, lessonPackage)"
                    >
                      <ion-icon slot="icon-only" :icon="closeOutline" />
                    </ion-button>
                  </ion-col>
                  <ion-col>
                    <ion-input
                      v-model.number="lessonPackage.lessons" label="Number of lessons" type="number"
                      label-placement="stacked" fill="outline" mode="md"
                    />
                  </ion-col>
                  <ion-col size="5">
                    <ion-input
                      v-model.number="lessonPackage.price" label="Total Price" type="number"
                      label-placement="stacked" fill="outline" mode="md"
                    >
                      <span slot="start">$</span>
                    </ion-input>
                  </ion-col>
                </ion-row>
              </template>
              <ion-row>
                <ion-col>
                  <ion-button fill="clear" size="small" @click="addPackage(pricingType)">
                    <ion-icon slot="start" :icon="addOutline" />
                    New lesson package
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-list>
      </div>

      <div v-if="selectedSubPage == 'schedule'" id="schedule_block">
        <!------------->
        <!-- Schedule -->
        <!------------->
        <schedule-form :lesson-type="lesson" />
      </div>

      <br>
      <!--------->
      <!--DEBUG-->
      <!--------->
      <!-- <ion-accordion-group>
        <ion-accordion value="second">
          <ion-item lines="none" slot="header"><ion-label>debug info</ion-label></ion-item>
          <div slot="content" class="ion-padding">
            <pre>{{ lesson }}</pre>
            <pre>{{ v$ }}</pre>
          </div>
        </ion-accordion>
      </ion-accordion-group> -->
    </ion-content>
    <ion-footer>
      <!-------------------->
      <!--Errors & Actions-->
      <!-------------------->
      <ion-text v-if="v$.$errors.length" color="danger">
        <ul data-testid="error-messages">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <li v-for="err in v$.$errors" :key="err.$uid" v-html="err.$message" />
        </ul>
      </ion-text>
      <ion-grid>
        <ion-row>
          <ion-col v-if="subPage == 'general'">
            <ion-button
              expand="block" color="danger"
              fill="outline" @click="removeLessonType"
            >
              Delete
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              expand="block" :disabled="v$.$invalid"
              fill="solid" color="primary" @click="saveLesson"
            >
              Save
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </ion-page>
</template>

<script setup>
const props = defineProps({
  lesson: { required: true, type: Object },
  locations: { required: true, type: Array },
  subPage: { required: true, type: String },
})

import {
  IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid,
  IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList,
  IonModal, IonNote, IonPage, IonRow,
  IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar,
} from '@ionic/vue'
import { addOutline, closeOutline } from 'ionicons/icons'
import { reactive, ref } from 'vue'

import { ALL_PRICING_TYPES, POSSIBLE_LESSON_LENGTHS } from '@/data/Instructor.schema'

import { EDMONTON_LOCATIONS } from '@/data/location'
import LocationsSelector from '@/components/LocationsSelector.vue'
import ScheduleForm from '@/views/instructors/schedules/ScheduleForm.vue'

const locationsModal = ref()

import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import { merge } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

const selectedSubPage = ref(props.subPage)

const DEFAULT_VALUES = () => {
  return {
    uuid: uuidv4(),
    public: true, // false means public can't book it
    length: 45,
    location: null,
    pricing: JSON.parse(JSON.stringify(ALL_PRICING_TYPES)),
    description: '',
    schedule: {
      dates: {
        mode: 'dates',
        days: [
          // { when: '2024-11-02', times: ['08:00'] },
        ],
      },
      weekly: {
        mode: 'weekly',
        validity: {
          from: new Date().toISOString(),
          to: new Date(new Date().getFullYear(), 11, 31).toISOString(),
        },
        days: [
          { when: 'Sun', times: [] },
          { when: 'Mon', times: [] },
          { when: 'Tue', times: [] },
          { when: 'Wed', times: [] },
          { when: 'Thu', times: [] },
          { when: 'Fri', times: [] },
          { when: 'Sat', times: [] },
        ],
      },
    },
  }
}

const lesson = reactive(merge({}, DEFAULT_VALUES(), props.lesson))

const rules = {
  location: {
    required: helpers.withMessage('Location is required', value => !!value),
  },
  length: {
    required: helpers.withMessage('Lesson Length is required', value => !!value),
  },
  pricing: {
    // validate that array of pricing types has at least one enabled and with a price
    nonEmpty: helpers.withMessage(
      'At least one Pricing must be enabled',
      value => Object.values(value).some(pricingType => pricingType.enabled && pricingType.price > 0),
    ),
    packages: helpers.withMessage(
      'Please complete packages information or remove them',
      function () {
        // validate that all packages have lessons and price
        return Object.values(lesson.pricing).every((pricingType) => {
          pricingType.packages ||= []
          return pricingType.packages.every(pckg => pckg.lessons > 0 && pckg.price > 0)
        })
      },
    ),
  },
}
const v$ = useVuelidate(rules, lesson)
v$.value.$touch()

const emit = defineEmits(['update:lesson', 'cancel', 'remove:lesson'])

function saveLesson() {
  emit('update:lesson', lesson)
}

function removeLessonType() {
  if (window.confirm('Are you sure?')) {
    emit('remove:lesson', lesson)
  }
}

function cancel() {
  emit('cancel')
}

function addPackage(pricingType) {
  if (!pricingType.packages) Object.assign(pricingType, { packages: [] })
  pricingType.packages.push({ lessons: 5, price: Math.round(pricingType.price * 5 * 0.8) })
}

function removePackage(pricingType, lessonPackage) {
  pricingType.packages = pricingType.packages.filter(pckg => pckg !== lessonPackage)
}

</script>

<style>
/* Subsequent modals should have background */
ion-modal.modal-default.show-modal~ion-modal.modal-default {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
</style>
