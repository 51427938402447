<template>
  <ion-item
    lines="full" detail="true" data-testid="selected-lesson-type"
    @click="openModal"
  >
    <ion-label v-if="selectedLessonType">
      <strong>{{ selectedLessonType.location.name }}</strong>
      <br>
      <ion-note>{{ formatLessonTypeDetails(lesson.pricing, lesson.length) }}</ion-note>
    </ion-label>
    <ion-label v-else>
      <ion-note>Select lesson type</ion-note>
    </ion-label>

    <ion-modal id="choose-lesson-type-modal" ref="modalRef" data-testid="choose-lesson-type-modal">
      <ion-list>
        <template v-for="(types, locationName) in groupedOptions" :key="locationName">
          <ion-item>
            <ion-label><strong>{{ locationName }}</strong></ion-label>
          </ion-item>
          <ion-item
            v-for="option in types" :key="`${option.lessonType.uuid}-${option.pricing.key}`"
            lines="none" :detail="true"
            @click="selectAndClose(option)"
          >
            <ion-label>
              {{ formatLessonTypeDetails(option.pricing, option.lessonType.length) }}
            </ion-label>
          </ion-item>
        </template>
      </ion-list>
    </ion-modal>
  </ion-item>
</template>

<script setup>
import { IonItem, IonLabel, IonList, IonModal, IonNote } from '@ionic/vue'
import { computed, ref } from 'vue'

import { ALL_PRICING_TYPES } from '@/data/Instructor.schema'

const props = defineProps({
  lesson: { type: Object, required: true, default: () => ({}) },
  lessonTypes: { type: Array, required: true, default: () => [] },
})

const emit = defineEmits(['selectLessonType'])
const modalRef = ref()

// Computed properties
const selectedLessonType = computed(() => props.lesson.lessonType)

const availableOptions = computed(() => {
  return props.lessonTypes
    .flatMap(lessonType =>
      Object.values(lessonType.pricing)
        .filter(pricing => pricing.enabled && pricing.price > 0)
        .map(pricing => ({
          pricing,
          lessonType,
        })),
    )
    .sort(sortByPricingType)
})

const groupedOptions = computed(() => {
  return availableOptions.value.reduce((grouped, option) => {
    const locationName = option.lessonType.location.name
    grouped[locationName] ??= []
    grouped[locationName].push(option)
    return grouped
  }, {})
})

// Methods
function sortByPricingType(a, b) {
  const pricingOrder = Object.keys(ALL_PRICING_TYPES)
  const aIndex = pricingOrder.indexOf(a.pricing.key)
  const bIndex = pricingOrder.indexOf(b.pricing.key)
  if (aIndex === -1) return 1
  if (bIndex === -1) return -1
  return aIndex - bIndex
}

function formatLessonTypeDetails(pricing, length) {
  return `${pricing.name} - ${length} min`
}

function openModal() {
  modalRef.value?.$el?.present()
}

async function selectAndClose(option) {
  emit('selectLessonType', option)
  modalRef.value?.$el?.dismiss()
}

// Auto-select first option if none selected
if (!props.lesson.lessonType && availableOptions.value.length) {
  selectAndClose(availableOptions.value[0])
}
</script>

<style>
/* CAN NOT BE SCOPED */
ion-modal#choose-lesson-type-modal {
  --width: 90%;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 14px;
}
</style>
